import React, { useEffect, useState } from "react";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import InfoByFrosty from "../../../Core/CommonV2/InfoByFrosty";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import CustomDatePicker from "../../../Core/CommonV2/CustomDatePicker";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import { useForm, Controller } from "react-hook-form";
import { client } from "../../../Core/utils/axiosClient";
import { getLabels } from "../../../Core/redux/api/scheduleAPI";
import { useParams, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import LabelColorPicker from "../MyBoard/LabelColorPicker";

const NewLabelModal = ({ visible, onClose, isMyBoard = false }) => {
  const [searchParams] = useSearchParams();
  const { projectId: projectIdFromParams } = useParams();
  const { projectList } = useAppSelector((state) => state.projects);
  const { labels } = useAppSelector((state) => state.schedule);

  const dispatch = useAppDispatch();

  const { handleSubmit, control, formState, reset, setError, watch } = useForm({
    defaultValues: {
      labelName: "",
      startDate: "",
      endDate: "",
    },
  });

  const [color, setColor] = useState("#165ABF");
  const [focus, setFocus] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState({
    id: 1,
    baseColor: "#D1E9FF",
    textColor: "#7DC7FF",
  });

  const projectId = projectIdFromParams || searchParams.get("projectId");
  const project = projectList.find((p) => p.id === projectId);

  const createLabel = async (data) => {
    try {
      const alreadyExists = labels
        .filter((label) => label.projectId === projectId)
        .find((l) => l.name === data.labelName);

      if (alreadyExists) {
        setError("labelName", {
          type: "alreadyExists",
          message: "Label already exists",
        });
      } else {
        await client.post("/schedule/label", {
          labelName: data.labelName,
          color: color || "#7DC7FF",
          projectId: isMyBoard ? null : projectId,
          isMyBoard: !projectId,
          startDate: data.startDate,
          endDate: data.endDate,
          labelColorId: selectedLabel.id,
        });

        reset();
        dispatch(getLabels({ projectId, isMyBoard }));
        setFocus(false);
        onClose();
      }
    } catch (e) {
      console.error("Error creating a new label:", e);
    }
  };

  const handleClose = () => {
    setFocus(false);
    onClose();
    reset();
  };

  useEffect(() => {
    if (visible) {
      setFocus(true);
    }
  }, [visible]);

  const startDate = watch("startDate");

  return (
    <CustomModal
      visible={visible}
      width="352px"
      onCancel={handleClose}
      body={
        focus ? (
          <div className="p-4">
            <div className="flex justify-between">
              <p className="text-black out-500-14">New Label</p>
              <div className="flex items-center gap-x-2.5">
                <InfoByFrosty
                  title="Label"
                  content="Add labels to your tasks, bugs, or events to help organize your boards. All the labels will be visible in every project of this workspace."
                />
                <button onClick={handleClose}>
                  <CrossIcon className="text-gray-700" />
                </button>
              </div>
            </div>
            <div className="flex items-start w-full mt-4 gap-x-[6px]">
              <Controller
                control={control}
                name="labelName"
                rules={{ required: "Please Add the label Name" }}
                render={({ field }) => (
                  <CustomInputBox
                    {...field}
                    autoFocus
                    className="w-full"
                    placeholder="Enter a name"
                    formState={formState}
                  />
                )}
              />
              <LabelColorPicker
                selectedLabel={selectedLabel}
                setSelectedLabel={setSelectedLabel}
              />
            </div>
            <div className="flex items-center justify-between mt-2 gap-x-2">
              <Controller
                control={control}
                name="startDate"
                rules={{ required: "Add Start Date" }}
                render={({ field }) => (
                  <Controller
                    control={control}
                    name="startDate"
                    rules={{ required: "Add Start Date" }}
                    render={({ field }) => (
                      <CustomDatePicker
                        {...field}
                        className="w-full text-zinc-800 out-300-14 bg-transparent rounded-[4px] border-gray-200"
                        format="DD MMM YYYY"
                        placeholder="Start date"
                        formState={formState}
                        disabledDate={(current) => {
                          const projectStartDate = dayjs(
                            project?.startDate
                          ).startOf("day");
                          const projectEndDate = dayjs(project?.endDate)
                            .startOf("day")
                            .add(1, "day");
                          const effectiveEndDate = field.value
                            ? dayjs(field.value).startOf("day").add(2, "day")
                            : projectEndDate;

                          return (
                            !isMyBoard &&
                            (current.isBefore(projectStartDate) ||
                              current.isAfter(effectiveEndDate))
                          );
                        }}
                      />
                    )}
                  />
                )}
              />
              <Controller
                control={control}
                name="endDate"
                rules={{
                  required: "Add End Date",
                  validate: (value) =>
                    !startDate ||
                    dayjs(value).isAfter(dayjs(startDate)) ||
                    "End date before start date",
                }}
                render={({ field }) => (
                  <CustomDatePicker
                    {...field}
                    className="w-full text-zinc-800 out-300-14 bg-transparent rounded-[4px] border-gray-200"
                    format="DD MMM YYYY"
                    placeholder="End date"
                    formState={formState}
                    disabled={!startDate}
                    disabledDate={(current) => {
                      const projectEndDate = dayjs(project?.endDate)
                        .startOf("day")
                        .add(1, "day");
                      const startDateWithoutTime =
                        dayjs(startDate).startOf("day");

                      return (
                        current.isBefore(startDateWithoutTime) ||
                        (!isMyBoard && current.isAfter(projectEndDate))
                      );
                    }}
                  />
                )}
              />
            </div>
            <div className="flex items-center justify-between mt-4">
              <CustomButton
                text="Manage Labels"
                className="bg-transparent text-primary-700 out-500-14"
                height="30px"
                onClick={() =>
                  dispatch(
                    updateSchedule({ key: "manageLabelModal", value: true })
                  )
                }
              />
              <div className="flex items-center">
                <CustomButton
                  text="Cancel"
                  className="text-gray-500 bg-transparent out-500-14"
                  height="30px"
                  onClick={handleClose}
                />
                <CustomButton
                  onClick={handleSubmit(createLabel)}
                  text="Create"
                  className="text-white bg-primary-600 out-500-14"
                  height="30px"
                />
              </div>
            </div>
          </div>
        ) : null
      }
    />
  );
};

export default NewLabelModal;
