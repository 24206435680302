import React, { useEffect, useState } from "react";
import PageTitle from "../PageTitle";
import CustomSearchBox from "../../../Core/CommonV2/CustomSearchBox";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { LabelItem } from "../../Schedule/Modals/ManageLabelsModal";
import { useSearchParams } from "react-router-dom";
import { getLabels } from "../../../Core/redux/api/scheduleAPI";
import ProjectsSettingsWrapper from "../ProjectsSettingsWrapper";
import NewLabelModal from "../../Schedule/Modals/NewLabelModal";

const Labels = () => {
  const [searchParams] = useSearchParams();
  const { labels } = useAppSelector((state) => state.schedule);
  const [newLabelModal, setNewLabelModal] = useState(false);
  const dispatch = useAppDispatch();
  const { projectList } = useAppSelector((state) => state.projects);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [search, setSearch] = useState("");
  const [isMyBoardLabel, setisMyBoardLabel] = useState(false);

  useEffect(() => {
    if (searchParams.has("myboard") && searchParams.get("myboard") === "true") {
      dispatch(getLabels({ projectId: null, isMyBoard: true }));
      setisMyBoardLabel(true);
      setMinDate(null);
      setMaxDate(null);
    } else if (searchParams.has("projectId")) {
      dispatch(getLabels({ projectId: searchParams.get("projectId") }));
      setisMyBoardLabel(false);
      const project = projectList.find(
        (p) => p.id === searchParams.get("projectId")
      );
      setMinDate(project.startDate);
      setMaxDate(project.endDate);
    }
  }, [searchParams, dispatch]);

  const handleSearch = () => {
    let filteredLabels = [...labels];
    if (search) {
      const regex = new RegExp(search, "i");

      filteredLabels = filteredLabels.filter((label) => {
        return regex.test(label.id) || regex.test(label.name);
      });
    }
    return filteredLabels;
  };

  const isMyBoard =
    searchParams.has("myboard") && searchParams.get("myboard") === "true";
  const subtext = isMyBoard
    ? "Manage your personal labels."
    : "Manage project labels.";

  return (
    <div>
      <PageTitle title={"Labels"} subtext={subtext} />
      <ProjectsSettingsWrapper>
        <div className="my-6 flex justify-between">
          <CustomSearchBox
            placeholder="Search label"
            search={search}
            setSearch={(e) => setSearch(e)}
            style={{ width: "50%" }}
          />
          <CustomButton
            height="30px"
            text=" New Label"
            onClick={() => setNewLabelModal(true)}
          />
        </div>
        <div>
          {handleSearch().map((label) => (
            <div key={label.id} className="border-b border-gray-200">
              <LabelItem
                label={label}
                projectId={searchParams.get("projectId")}
                maxDate={maxDate}
                minDate={minDate}
              />
            </div>
          ))}
        </div>
      </ProjectsSettingsWrapper>

      <NewLabelModal
        visible={newLabelModal}
        onClose={() => setNewLabelModal(false)}
        isMyBoard={isMyBoardLabel}
      />
    </div>
  );
};

export default Labels;
