import React, { useState } from "react";
import PageTitle from "../PageTitle";
import CustomSearchBox from "../../../Core/CommonV2/CustomSearchBox";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import NewTeamModal from "../../Schedule/Modals/NewTeamModal";
import { EditIcon } from "../../../Core/svgV2/EditIcon";
import { DeleteIcon } from "../../../Core/svgV2/DeleteIcon";
import { Tooltip } from "antd";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import { useGetTeams } from "../../../Core/redux/ReactQueryHooksV3/useReportsAPI";
import { useQueryClient } from "react-query";
import { client } from "../../../Core/utils/axiosClient";
import { CrossIconSecondary } from "../../../Core/svgV2/CrossIcon";
import usePermission from "../../../Core/hooks/usePermission";

interface FormState {
  errors: {
    teamName?: { message: string };
  };
}

interface Team {
  id: number;
  teamName: string;
}

interface TeamItemProps {
  team: Team;
  teams: Team[];
  canEdit: boolean;
}

export const TeamItem: React.FC<TeamItemProps> = ({
  team,
  teams,
  canEdit = false,
}) => {
  const [edit, setEdit] = useState(false);
  const [teamName, setTeamName] = useState(team.teamName);
  const [tempTeamName, setTempTeamName] = useState(team.teamName);
  const [formState, setFormState] = useState<FormState>({ errors: {} });
  const queryClient = useQueryClient();

  // Function to handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setTeamName(inputValue);

    // Validate the input
    if (!inputValue.trim()) {
      setFormState({
        errors: { teamName: { message: "Team name cannot be empty." } },
      });
    } else {
      // Check for duplicate team names
      const isDuplicateName = teams.some(
        (t) =>
          t.teamName.toLowerCase() === inputValue.trim().toLowerCase() &&
          t.id !== team.id
      );

      if (isDuplicateName) {
        setFormState({
          errors: { teamName: { message: "Team name already exists." } },
        });
      } else {
        setFormState({ errors: {} }); // Clear errors if valid
      }
    }
  };

  const handleUpdateLabel = async () => {
    if (formState.errors.teamName) return; // Prevent update if there's an error

    try {
      const { data } = await client.put(`teams/update-team/${team.id}`, {
        teamName,
      });
      console.log("Team updated successfully:", data);

      await queryClient.invalidateQueries("teams");
    } catch (e) {
      console.log("Error updating team name", e);
    } finally {
      setEdit(false);
    }
  };

  const handleDeleteTeam = async () => {
    try {
      const { data } = await client.delete(`teams/delete-team/${team.id}`);
      console.log("Team deleted successfully:", data);

      await queryClient.invalidateQueries("teams");
    } catch (e) {
      console.log("Error deleting team", e);
    }
  };

  const handleCancelEdit = () => {
    setEdit(false);
    setTeamName(tempTeamName);
    setFormState({ errors: {} });
  };

  if (edit) {
    return (
      <div className="py-2 gap-x-2 flex justify-between">
        <CustomInputBox
          className="w-full"
          autoFocus
          placeholder="Enter a name"
          value={teamName}
          name="teamName"
          onChange={handleInputChange}
          formState={formState}
        />
        <CustomButton
          text="Save"
          className="bg-transparent text-primary-700 out-500-14"
          height="30px"
          onClick={handleUpdateLabel}
          disabled={!teamName.trim() || !!formState.errors.teamName} // Disable if input is invalid
        />
        <CustomButton
          text="Cancel"
          className="bg-transparent text-gray-500 out-500-14"
          height="30px"
          onClick={handleCancelEdit}
        />
      </div>
    );
  }

  return (
    <div className="py-2.5 group flex justify-between">
      {teamName}
      {canEdit && (
        <div className="group-hover:flex hidden gap-x-4 items-center">
          <Tooltip title="Edit" placement="left">
            <button
              onClick={() => {
                setEdit(true);
                setTempTeamName(teamName); // Store current team name before editing
              }}
            >
              <EditIcon />
            </button>
          </Tooltip>
          <Tooltip title="Delete" placement="left">
            <button onClick={handleDeleteTeam}>
              <DeleteIcon />
            </button>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

const WorkspaceTeams = () => {
  const [newTeamVisible, setNewTeamVisible] = useState(false);
  const [search, setSearch] = useState("");
  const { data, isLoading, error } = useGetTeams(); // Using the useGetTeams hook to fetch teams
  const { hasPermission } = usePermission("1");

  const EmptyState = () => {
    return (
      <div className=" w-full flex items-center justify-center flex-col rounded-lg gap-y-6 h-[calc(100vh-300px)] px-4 py-6">
        <div className="out-500-14 text-center text-black">No team created</div>
        <img
          src="/images/v2/settings/frosty-search.svg"
          className="w-[146px]"
        />
        <p className="out-300-14 text-gray-500">
          Create a new team and assign to members
        </p>

        {hasPermission && (
          <CustomButton
            text="Create New Team"
            height="30px"
            onClick={() => setNewTeamVisible(true)}
          />
        )}
      </div>
    );
  };

  const handleSearch = () => {
    let filteredTeams = [...(data?.teams || [])];
    if (search) {
      const regex = new RegExp(search, "i");

      filteredTeams = filteredTeams.filter((team) => {
        return regex.test(team.id) || regex.test(team.teamName);
      });
    }
    return filteredTeams;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching teams</div>;
  }

  return (
    <div>
      <PageTitle title={"Teams"} subtext={"Manage workspace teams."} />
      <div className="my-6 flex justify-between">
        <CustomSearchBox
          placeholder="Search by name"
          style={{ width: "50%" }}
          search={search}
          setSearch={(e) => setSearch(e)}
          suffix={
            search ? (
              <span onClick={() => setSearch("")} className="cursor-pointer">
                <CrossIconSecondary />
              </span>
            ) : null
          }
        />

        {hasPermission && (
          <CustomButton
            height="30px"
            text="Create Team"
            onClick={() => setNewTeamVisible(true)}
          />
        )}
      </div>
      {data?.teams?.length === 0 ? (
        <EmptyState />
      ) : (
        <div>
          {handleSearch().map((team) => (
            <div key={team.id} className="border-b border-gray-200">
              <TeamItem
                team={team}
                teams={data?.teams || []}
                canEdit={hasPermission}
              />
            </div>
          ))}
        </div>
      )}

      <NewTeamModal
        visible={newTeamVisible}
        onClose={() => setNewTeamVisible(false)}
        teams={data?.teams || []} // Passing teams data to the modal
      />
    </div>
  );
};

export default WorkspaceTeams;
