/* eslint-disable react/display-name */
import React, { useCallback, useState } from "react";
import { ResponsiveBar, BarItemProps } from "@nivo/bar";
import { useTooltip } from "@nivo/tooltip";
import { SettingIcon } from "../../Core/svgV2/SettingsIcon";
import { DotsVerticalIcon } from "../../Core/svgV2/DotsVertical";
import { Popover, Checkbox, Divider, Tooltip } from "antd";
import { DeleteScheduleIcon } from "../../Core/svgV3/DeleteIcon";
import { updateDashboard } from "../../Core/redux/slices/dashboard.slice";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { ReportsInfoIcon } from "../../Core/svgV3/InfoIcon";
import ReactDOM from "react-dom";

type BarDatum = {
  max: any;
  id: string;
  "Time estimated": number;
  "Time spent": number;
  height: number;
};

const formatTime = (timeInHours) => {
  if (timeInHours === 0) return "0h 0m";
  const hours = Math.floor(timeInHours);
  const minutes = Math.round((timeInHours - hours) * 60);
  return `${hours}h ${minutes}m`;
};

export const CustomGridLine = (y, color, label) => {
  return ({ yScale, innerWidth }) => {
    return (
      <>
        {/* Dashed line with strokeWidth 1 */}
        <line
          x1={0}
          x2={innerWidth}
          y1={yScale(y)}
          y2={yScale(y)}
          stroke={color}
          strokeWidth={1} // Set stroke width to 1
          strokeDasharray="4 4" // Make the line dashed
        />
        {/* <text
          x={-10}
          y={yScale(y)}
          textAnchor="end"
          dominantBaseline="central"
          fontWeight="bold"
          fontSize="12"
          style={{
            fill: color,
          }}
        >
          {y}
        </text> */}
        <text
          x={innerWidth + 10}
          y={yScale(y)}
          textAnchor="start"
          dominantBaseline="central"
          style={{
            fill: color,
          }}
        >
          {label}
        </text>
      </>
    );
  };
};

// Custom Bar Component
const CustomBar: React.FC<
  BarItemProps<BarDatum> & { selectedKeys: string[] }
> = ({ bar, selectedKeys }) => {
  const { "Time estimated": estimatedTime = 0, "Time spent": actualTime = 0 } =
    bar.data.data;

  // console.log(bar, "baro");

  const maxValue = bar.data.data.height || 10; // Fallback to a default max value if not provided

  // Calculate the scale based on the max value for proportional height
  const estimatedHeight = (estimatedTime * bar.height) / maxValue; // Scale for estimated time
  const actualHeight = (actualTime * bar.height) / maxValue; // Scale for actual time

  // Adjust y-positions to ensure the bars are correctly placed relative to their heights
  const estimatedY = bar.y + bar.height - estimatedHeight; // Adjust for estimated bar
  const bulletY = bar.y + bar.height - actualHeight; // Adjust for actual bullet

  const barColor = "#D1E9FF"; // Light blue for the estimated bar
  const bulletColor = "#2E90FA"; // Dark blue for the actual time bullet

  // Tooltip handlers
  const { showTooltipFromEvent, hideTooltip } = useTooltip();
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [tooltipContent, setTooltipContent] = useState<JSX.Element | null>(
    null
  );

  const handleMouseMove = useCallback((event: React.MouseEvent<SVGElement>) => {
    setTooltipPosition({
      top: event.clientY + 10, // Adjust Y position
      left: event.clientX + 10, // Adjust X position
    });
  }, []);

  const handleMouseEnter = (event: React.MouseEvent<SVGElement>) => {
    const tooltip = (
      <div className="shadow-xl rounded-lg bg-white p-2 whitespace-nowrap">
        {/* If "Time estimated" is selected or neither is selected, show the estimated time */}
        {(selectedKeys.includes("Time estimated") ||
          (!selectedKeys.includes("Time estimated") &&
            !selectedKeys.includes("Time spent"))) && (
          <>
            <span
              style={{
                display: "inline-block",
                width: "10px",
                height: "10px",
                backgroundColor: "#D1E9FF",
                borderRadius: "50%",
                marginRight: "5px",
              }}
            />
            Time estimated: <strong>{formatTime(estimatedTime || 0)}</strong>
            <br />
          </>
        )}

        {/* If "Time spent" is selected or neither is selected, show the actual time */}
        {(selectedKeys.includes("Time spent") ||
          (!selectedKeys.includes("Time estimated") &&
            !selectedKeys.includes("Time spent"))) && (
          <>
            <span
              style={{
                display: "inline-block",
                width: "10px",
                height: "10px",
                backgroundColor: "#2E90FA",
                borderRadius: "50%",
                marginRight: "5px",
              }}
            />
            Time spent: <strong>{formatTime(actualTime || 0)}</strong>
          </>
        )}
      </div>
    );
    setTooltipContent(tooltip);
  };

  const handleMouseLeave = () => {
    hideTooltip();
    setTooltipContent(null); // Hide the tooltip
  };

  const textOffset = 12; // Fixed offset above bars/bullets

  // Determine the text for percentage
  const percentageText =
    estimatedTime > 0
      ? `${(((estimatedTime - actualTime) / estimatedTime) * 100).toFixed(1)}%`
      : "0%";

  const timeEstimatedText =
    estimatedTime > 0 ? `${estimatedTime.toFixed(0)}h` : "";
  const timeSpentText = actualTime > 0 ? `${actualTime.toFixed(0)}h` : "";

  // Function to determine text y-position with dynamic offset based on text height
  const getTextY = (yPosition: number, text: string) => {
    const textLength = text.length;
    const calculatedOffset = textOffset + textLength * 4; // Adjust this multiplier based on font size and desired offset
    return yPosition - calculatedOffset;
  };

  return (
    <g>
      {/* Case when both "Time estimated" and "Time spent" are not selected, render both bar and bullet */}
      {!selectedKeys.includes("Time estimated") &&
        !selectedKeys.includes("Time spent") && (
          <>
            {/* Render the estimated time (light blue bar) */}
            {estimatedTime > 0 && (
              <rect
                x={bar.x + bar.width / 2 - 10} // Centered horizontally
                y={estimatedY} // Adjusted y-position based on scaled height
                width={20}
                height={estimatedHeight} // Proportional height for estimated time
                fill={barColor}
                rx={5}
                onMouseEnter={handleMouseEnter}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              />
            )}

            {/* Render the actual time (dark blue bullet) */}
            {actualTime > 0 && (
              <>
                <line
                  x1={bar.x + bar.width / 2}
                  y1={bulletY} // Position the bullet line based on actual time
                  x2={bar.x + bar.width / 2}
                  y2={bar.y + bar.height}
                  stroke={bulletColor}
                  strokeWidth={2}
                  onMouseEnter={handleMouseEnter}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                />
                <circle
                  cx={bar.x + bar.width / 2}
                  cy={bulletY} // Position the bullet based on actual time
                  r={5}
                  fill={bulletColor}
                  onMouseEnter={handleMouseEnter}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                />
              </>
            )}
          </>
        )}

      {/* Render the estimated time (light blue bar) only when selected */}
      {selectedKeys.includes("Time estimated") && estimatedTime > 0 && (
        <rect
          x={bar.x + bar.width / 2 - 10} // Centered horizontally
          y={estimatedY} // Adjusted y-position based on scaled height
          width={20}
          height={estimatedHeight} // Proportional height for estimated time
          fill={barColor}
          rx={5}
          onMouseEnter={handleMouseEnter}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        />
      )}

      {/* Render the actual time (dark blue bullet) only when selected */}
      {selectedKeys.includes("Time spent") && actualTime > 0 && (
        <>
          <line
            x1={bar.x + bar.width / 2}
            y1={bulletY} // Position the bullet line based on actual time
            x2={bar.x + bar.width / 2}
            y2={bar.y + bar.height}
            stroke={bulletColor}
            strokeWidth={2}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          />
          <circle
            cx={bar.x + bar.width / 2}
            cy={bulletY} // Position the bullet based on actual time
            r={5}
            fill={bulletColor}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          />
        </>
      )}

      {/* Optional text to display the difference between estimated and actual times */}
      {(selectedKeys.includes("Time estimated") &&
        selectedKeys.includes("Time spent") &&
        estimatedTime > 0 &&
        actualTime > 0) ||
      (!selectedKeys.includes("Time estimated") &&
        !selectedKeys.includes("Time spent") &&
        estimatedTime > 0 &&
        actualTime > 0) ? (
        // Display percentage difference
        <text
          x={bar.x + bar.width / 2}
          y={getTextY(Math.min(estimatedY, bulletY), percentageText) + 4} // Use dynamic position calculation
          textAnchor="middle"
          fontWeight={300}
          fill="#292927"
          transform={`rotate(-90, ${bar.x + bar.width / 2}, ${getTextY(
            Math.min(estimatedY, bulletY),
            percentageText
          )})`} // Adjust rotation point to text position
        >
          {percentageText}
        </text>
      ) : selectedKeys.includes("Time estimated") &&
        !selectedKeys.includes("Time spent") &&
        estimatedTime > 0 ? (
        // Display only estimated time if selected
        <text
          x={bar.x + bar.width / 2}
          y={getTextY(estimatedY, timeEstimatedText) + 4} // Use dynamic position calculation
          textAnchor="middle"
          fontWeight={300}
          fill="#292927"
          transform={`rotate(-90, ${bar.x + bar.width / 2}, ${getTextY(
            estimatedY,
            timeEstimatedText
          )})`} // Adjust rotation point to text position
        >
          {timeEstimatedText}
        </text>
      ) : selectedKeys.includes("Time spent") &&
        !selectedKeys.includes("Time estimated") &&
        actualTime > 0 ? (
        // Display only actual time if selected
        <text
          x={bar.x + bar.width / 2}
          y={getTextY(bulletY, timeSpentText) + 4} // Use dynamic position calculation
          textAnchor="middle"
          fontWeight={300}
          fill="#292927"
          transform={`rotate(-90, ${bar.x + bar.width / 2}, ${getTextY(
            bulletY,
            timeSpentText
          )})`} // Adjust rotation point to text position
        >
          {timeSpentText}
        </text>
      ) : null}
      {tooltipContent &&
        ReactDOM.createPortal(
          <div
            style={{
              position: "absolute",
              top: tooltipPosition.top,
              left: tooltipPosition.left,
            }}
          >
            {tooltipContent}
          </div>,
          document.body // Render outside the chart
        )}
    </g>
  );
};

export const calculateNiceTickInterval = (maxValue) => {
  const range = maxValue;
  const orderOfMagnitude = Math.floor(Math.log10(range)); // Get the magnitude of the range
  const scale = Math.pow(10, orderOfMagnitude); // Calculate the scale

  // Default interval
  let interval = scale / 2;

  // Adjust the interval based on the number of ticks
  if (range / interval > 10) {
    interval = scale; // If too many ticks, increase interval
  } else if (range / interval < 5) {
    interval = scale / 5; // If too few ticks, decrease interval
  }

  // Special handling for larger ranges
  if (range > 10000 && range <= 50000) {
    interval = 5000; // Set tick interval to 5000 for values between 10k and 50k
  } else if (range > 50000 && range <= 100000) {
    interval = 10000; // Set tick interval to 10,000 for values between 50k and 100k
  } else if (range > 100000) {
    interval = 20000; // Set tick interval to 20,000 for values over 100k
  }

  return interval;
};

// Function to generate tick values dynamically based on max value and interval
export const generateDynamicTickValues = (maxValue) => {
  const interval = calculateNiceTickInterval(maxValue); // Get interval
  const roundedMaxValue = roundToNextMaxValue(maxValue, interval); // Round maxValue to the next tick

  const numTicks = Math.ceil(roundedMaxValue / interval); // Calculate number of ticks

  // Generate tick values
  return Array.from({ length: numTicks + 2 }, (_, i) => i * interval);
};

// Function to round max value to the next tick based on the interval
export const roundToNextMaxValue = (maxValue, interval) => {
  return Math.ceil(maxValue / interval) * interval + interval * 2; // Round up to nearest multiple of the interval
};

// Custom Bar Chart Component
const CustomBarChart: React.FC<{
  data: BarDatum[];
  selectedKeys: string[];
  weeks: number;
  indexByX: string;
  indexByY: string;
  legendTextX: string;
  maxValue: number;
  showValues: boolean;
}> = ({
  data,
  selectedKeys,
  weeks,
  indexByX,
  indexByY,
  legendTextX,
  maxValue = 20, // Default max value
  showValues,
}) => {
  const tickRotationBottom = data.length > 4 ? -45 : 0;

  // Get the last object and its 'max' value
  const lastObject = data?.[data.length - 1];
  const max = Math.max(lastObject?.max, 50); // Use max from the last object or fallback to provided maxValue
  maxValue = max
    ? roundToNextMaxValue(max, calculateNiceTickInterval(max))
    : 20;

  // Filter out the last object from the data array
  const filteredData = data?.slice(0, -1);

  // Generate tick values based on the derived max value
  const tickValues = generateDynamicTickValues(maxValue);
  console.log(maxValue, "max bullet", max);

  // Determine whether to apply overflow style
  const isOverflow = filteredData?.length > 45;

  const formatTickValue = (value: number) => {
    if (value >= 10000) {
      return `${(value / 1000).toFixed(0)}K`; // Format in K for thousands
    }
    return value.toString(); // Otherwise, return as is
  };

  return (
    <div className="relative bg-white flex">
      {/* Left Axis Chart */}
      <div
        className="min-w-[50px] max-w-[50px]"
        style={{
          height: showValues ? "400px" : "452.2px",
        }}
      >
        <ResponsiveBar
          data={filteredData}
          keys={["height"]}
          indexBy={indexByX}
          margin={{ top: 10, right: 0, bottom: 80, left: 20 }}
          padding={0.3}
          layout="vertical"
          colors={{ scheme: "nivo" }}
          axisLeft={{
            tickSize: 0,
            tickValues: tickValues,
            tickPadding: -25,
            tickRotation: 0,
            legend: indexByY,
            legendOffset: -8,
            legendPosition: "middle",
            format: formatTickValue,
          }}
          axisBottom={null}
          enableGridX={false}
          enableGridY={true}
          minValue={0}
          maxValue={maxValue}
          animate={true}
          layers={["axes"]}
          theme={{
            axis: {
              legend: {
                text: {
                  fontFamily: "Outfit",
                  fontSize: 12,
                  fontWeight: 300,
                  textTransform: "capitalize",
                  fill: "#667085",
                },
              },
            },
            text: {
              fontFamily: "Outfit",
              fontSize: 10,
              fontWeight: 500,
              textTransform: "capitalize",
              fill: "#667085",
            },
          }}
        />
      </div>

      {/* Main Chart */}
      <div className={`relative ${isOverflow ? "overflow-x-auto" : "w-full"}`}>
        <div
          className={`${showValues ? "h-[400px]" : "h-[452.2px]"} ${
            isOverflow ? "w-[2300px]" : "w-full"
          }`}
        >
          <ResponsiveBar
            data={filteredData}
            keys={["height"]}
            indexBy={indexByX}
            margin={{ top: 10, right: 0, bottom: 80, left: 5 }} // Margin for the chart
            padding={0.3}
            layout="vertical"
            barComponent={(props) => (
              <CustomBar {...props} selectedKeys={selectedKeys} />
            )}
            colors={{ scheme: "nivo" }}
            axisBottom={{
              tickSize: 0,
              tickPadding: 11,
              tickRotation: tickRotationBottom,
              renderTick: (tick) => {
                const label = tick.value || "";
                const isTruncated = label?.length > 13 && data?.length > 4;
                const displayText = isTruncated
                  ? label.substring(0, 10) + "..."
                  : label;

                return (
                  <g transform={`translate(${tick.x},${tick.y + 10})`}>
                    <text
                      textAnchor={tickRotationBottom === 0 ? "middle" : "end"} // Adjust alignment based on rotation
                      fontSize={12}
                      fontWeight={300}
                      fontFamily="Outfit"
                      fill="#667085"
                      transform={`rotate(${tickRotationBottom}, 0, 0)`} // Apply the rotation
                      dy={tickRotationBottom === 0 ? "0.35em" : "0.35em"}
                      style={{
                        textTransform: "capitalize",
                        cursor: "default",
                        // paddingTop: "4px",
                      }}
                    >
                      {/* Tooltip on hover for full label */}
                      <title>
                        {label
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                      </title>
                      {displayText}
                    </text>
                  </g>
                );
              },
            }}
            layers={[
              "grid",
              "axes",
              "bars",
              CustomGridLine(0, "#969696", ""),
              "annotations",
              "legends",
            ]}
            axisLeft={null} // Disable left axis on the chart since we are rendering it separately
            minValue={0}
            maxValue={maxValue} // Use the derived max value
            enableGridX={false}
            enableGridY={true}
            gridYValues={tickValues} // Pass the tick values for grid lines
            animate={true}
            theme={{
              axis: {
                legend: {
                  text: {
                    fontFamily: "Outfit",
                    fontSize: 12,
                    fontWeight: 500,
                    textTransform: "capitalize",
                    fill: "#667085",
                  },
                },
              },
              text: {
                fontFamily: "Outfit",
                fontSize: 10,
                fontWeight: 500,
                textTransform: "capitalize",
                fill: "#667085",
              },
              grid: {
                line: {
                  stroke: "#ccc", // Default color for other grid lines
                  strokeWidth: 1,
                  strokeDasharray: "4 4", // Dotted style for grid lines
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

const colorMapping = {
  "Time estimated": "#D1E9FF",
  "Time spent": "#2E90FA",
};

const CustomBulletChart = ({
  type = 1,
  data,
  keys,
  indexByX = "project",
  indexByY = "Hours",
  heading = "Team time on projects",
  tooltip = "Test",
  showTotalValues = false,
  Total1Text = "Total Time Estimated",
  Total2Text = "Total Time Spent",
  heightKey = 21,
  weeks = 1,
  legendTextX = indexByX,
}) => {
  const [selectedKeys, setSelectedKeys] = useState([
    "Time estimated",
    "Time spent",
  ]);

  const dispatch = useAppDispatch();
  const { selectedCharts } = useAppSelector((state) => state.dashboard);
  const [isSettingPopoverOpen, setIsSettingPopoverOpen] = useState(false);
  const [isDotsPopoverOpen, setIsDotsPopoverOpen] = useState(false);

  console.log(indexByX, "indexByX", data);
  const handleKeyChange = (key) => {
    // If the key is already selected, remove it; otherwise, add it
    if (selectedKeys.includes(key)) {
      setSelectedKeys(
        selectedKeys.filter((selectedKey) => selectedKey !== key)
      );
    } else {
      setSelectedKeys([...selectedKeys, key]);
    }
  };

  const total1Key = "Time estimated";
  const total2Key = "Time spent";

  const total1 = data.reduce((sum, item) => sum + (item[total1Key] || 0), 0);

  const total2 = data.reduce((sum, item) => sum + (item[total2Key] || 0), 0);

  const popoverContent = (
    <div className="border border-gray-200 rounded-lg p-[1px]">
      <div className="flex justify-center py-1 px-2 border-b border-gray-200">
        Display Setting
      </div>
      <div className="flex flex-col">
        {keys?.map((key, index) => (
          <div
            key={key}
            className={`py-1 px-2 ${
              selectedKeys.includes(key) ? "bg-blue-100" : "hover:bg-gray-100"
            } ${index === keys.length - 1 ? "rounded-b-[7px]" : ""}`}
          >
            <Checkbox
              checked={selectedKeys.includes(key)}
              onChange={() => handleKeyChange(key)}
            >
              {key}
            </Checkbox>
          </div>
        ))}
      </div>
    </div>
  );

  const popoverContentThreeDots = (
    <div
      className=" flex gap-2 items-center justify-start hover:bg-gray-200 cursor-pointer rounded p-2"
      onClick={() => {
        const chartKey = `chart${type}`;

        dispatch(
          updateDashboard({
            key: "selectedCharts",
            value: {
              ...selectedCharts,
              [chartKey]: false, // Dynamically updating the correct chart
            },
          })
        );
      }}
    >
      <div>
        <DeleteScheduleIcon className="text-error-600" />
      </div>
      <div className="text-error-600">Delete Chart</div>
    </div>
  );

  const FormattedTime: React.FC<{ time: number }> = ({ time }) => {
    const hours = Math.floor(time);
    const minutes = Math.round((time - hours) * 60);

    return (
      <div className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px]">
        {hours > 0 && (
          <>
            {hours}
            <span className="out-500-14 text-gray-500 mx-1">h</span>
          </>
        )}
        {minutes > 0 && (
          <>
            {minutes}
            <span className="out-500-14 text-gray-500 ml-1">m</span>
          </>
        )}
        {hours === 0 && minutes === 0 && (
          <>
            {0}
            <span className="out-500-14 text-gray-500 ml-1">h</span>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4 p-4 bg-white rounded-xl border border-gray-200">
      <div className="flex justify-between">
        <div className="flex items-center gap-1">
          <div className="out-500-16 text-black">{heading}</div>
          <Tooltip
            title={<div className="">{tooltip}</div>}
            placement={"right"}
          >
            <div>
              <ReportsInfoIcon className=" text-gray-500" />
            </div>
          </Tooltip>
        </div>

        <div className="flex">
          {/* Tooltip and Popover for Settings */}
          <Tooltip title="Display Settings" placement="topRight">
            <Popover
              content={popoverContent}
              trigger="click"
              placement="bottomRight"
              className={`p-[5px] rounded ${
                isSettingPopoverOpen ? "bg-gray-200" : "hover:bg-gray-200"
              }`}
              arrow={false}
              onOpenChange={(visible) => setIsSettingPopoverOpen(visible)}
            >
              <SettingIcon className="text-black cursor-pointer" />
            </Popover>
          </Tooltip>

          {/* Tooltip and Popover for Dots */}
          <Tooltip title="More actions" placement="topRight">
            <Popover
              content={popoverContentThreeDots}
              trigger="click"
              placement="bottomRight"
              className={`p-[5px] rounded ${
                isDotsPopoverOpen ? "bg-gray-200" : "hover:bg-gray-200"
              }`}
              arrow={false}
              onOpenChange={(visible) => setIsDotsPopoverOpen(visible)}
            >
              <DotsVerticalIcon className="text-gray-500" />
            </Popover>
          </Tooltip>
        </div>
      </div>

      {/* Show totals if selected keys exist */}
      {showTotalValues && (
        <div className="flex items-center gap-4 h-[52.2px]">
          {/* If both are unselected, show both */}
          {!selectedKeys.includes(total1Key) &&
            !selectedKeys.includes(total2Key) && (
              <>
                <div className="flex flex-col gap-1">
                  <div className="out-500-10 text-gray-400 uppercase">
                    {Total1Text}
                  </div>
                  <div className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px]">
                    <FormattedTime time={total1 || 0} />
                  </div>
                </div>
                <Divider
                  type="vertical"
                  style={{ height: "40px", margin: "0" }}
                />
                <div className="flex flex-col gap-1">
                  <div className="out-500-10 text-gray-400 uppercase">
                    {Total2Text}
                  </div>
                  <div className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px]">
                    <FormattedTime time={total2 || 0} />
                  </div>
                </div>
              </>
            )}

          {/* Show Total1 only when total1Key is selected */}
          {selectedKeys.includes(total1Key) && (
            <div className="flex flex-col gap-1">
              <div className="out-500-10 text-gray-400 uppercase">
                {Total1Text}
              </div>
              <div className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px]">
                <FormattedTime time={total1 || 0} />
              </div>
            </div>
          )}

          {/* Show divider if both Total1 and Total2 are selected */}
          {selectedKeys.includes(total1Key) &&
            selectedKeys.includes(total2Key) && (
              <Divider
                type="vertical"
                style={{ height: "40px", margin: "0" }}
              />
            )}

          {/* Show Total2 only when total2Key is selected */}
          {selectedKeys.includes(total2Key) && (
            <div className="flex flex-col gap-1">
              <div className="out-500-10 text-gray-400 uppercase">
                {Total2Text}
              </div>
              <div className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px]">
                <FormattedTime time={total2 || 0} />
              </div>
            </div>
          )}
        </div>
      )}

      {/* Render CustomBarChart with selected keys */}
      <CustomBarChart
        data={data}
        selectedKeys={selectedKeys}
        weeks={weeks}
        indexByX={indexByX}
        indexByY={indexByY}
        legendTextX={legendTextX}
        maxValue={50}
        showValues={showTotalValues}
      />
      <div className={`relative bg-gray-50 rounded py-1 px-1]`}>
        <div className="absolute top-[-10px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 out-300-12 text-[#667085] capitalize">
          {legendTextX}
          <span className="out-300-12 text-[#667085]">s</span>
        </div>
        <div
          className={`h-[${heightKey}px] items-center justify-center flex gap-4`}
          style={{ height: `${heightKey}px` }}
        >
          {Object.keys(colorMapping)?.map((key) => (
            <div
              key={key}
              onClick={() => {
                // Toggle the key selection
                if (selectedKeys.includes(key)) {
                  setSelectedKeys(
                    selectedKeys.filter((selectedKey) => selectedKey !== key)
                  );
                } else {
                  setSelectedKeys([...selectedKeys, key]);
                }
              }}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "6px",
                marginBottom: "2px",
                cursor: "pointer",
                opacity: selectedKeys.includes(key) ? 1 : 0.5, // Grey out if not selected
              }}
            >
              <div
                style={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: colorMapping[key],
                  borderRadius: "50%",
                }}
              />
              <span className="text-[#667085] font-light capitalize">
                {key}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomBulletChart;
