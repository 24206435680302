import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getProjectList, getProjectPermission, getTenantPermission } from "../api/projectAPI";

const initialState = {
  loading: false,
  error: {},
  status: "idle",
  loggedIn: false,
  projectList: [],
  refreshCount: 0,
  selectedProject: null,
  projectPermission: [],
  permissionArray: [],
  tenantPermissionArray: [],
  permissionInfo: null,
  overview: null,
  schedule: null,
  vault: null,
};

export const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    addDummyDataForProject: (state, action) => {
      const dummyState = action.payload;
      Object.keys(dummyState).forEach((key) => (state[key] = dummyState[key]));
    },
    changeProject: (state, action) => {
      state.selectedProject = action.payload;
    },
    resetToInitialProjects: (state, action) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key])
      );
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getProjectList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectList.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "fulfilled";
        state.projectList = action.payload;
      })
      .addCase(getProjectList.rejected, (state, action) => {
        state.loading = false;
        state.status = "rejected";
        const appTourInProgress = localStorage.getItem("appTour");
        if (appTourInProgress !== "1") {
          state.projectList = [];
        }
        state.error = action.error;
      })
      .addCase(getProjectPermission.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectPermission.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "fulfilled";
        state.projectPermission = action.payload.permissions;
        state.permissionArray = action.payload.permissionsArray;
      })
      .addCase(getProjectPermission.rejected, (state, action) => {
        state.loading = false;
        state.status = "rejected";
        state.error = action.error;
      })
      .addCase(getTenantPermission.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTenantPermission.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "fulfilled";
        state.tenantPermissionArray = action.payload.permissionsArrayByProject;
      })
      .addCase(getTenantPermission.rejected, (state, action) => {
        state.loading = false;
        state.status = "rejected";
        state.error = action.error;
      });
  },
});

export const { changeProject, resetToInitialProjects, addDummyDataForProject } =
  projectSlice.actions;
export default projectSlice.reducer;
