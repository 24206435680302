import React, { useEffect, useState } from "react";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import CustomBadge from "../../../Core/CommonV2/CustomBadge";
import { CheckIcon } from "../../../Core/svgV2/Check";
import { Checkbox, DatePicker, Input, Popover, Tooltip } from "antd";
import CustomSelect, { MultiSelect } from "../../../Core/CommonV2/CustomSelect";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import getUsersName from "../../../Core/utils/getUserName";
import { PhaseIcon } from "../../../Core/svgV2/PhaseIcon";
import PriorityFlag from "../../../Core/svgV2/PriorityFlag";
import {
  getPriorityDetails,
  getStatusDetails,
} from "../../../Core/utils/scheduleUtils";
import { PlusIcon, PlusIconSecondary } from "../../../Core/svgV2/PlusIcon";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { CalendarIcon } from "../../../Core/svgV2/CalendarIcon";
import dayjs from "dayjs";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import { useParams } from "react-router-dom";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { client } from "../../../Core/utils/axiosClient";
import {
  getGoalDetails,
  updateParentTaskTimeLog,
} from "../../../Core/redux/api/scheduleAPI";
import WaddledItModal from "../Modals/WaddledItModal";
import { Taskicon } from "../../../Core/svgV2/TaskIcon";
import CustomSearchBox from "../../../Core/CommonV2/CustomSearchBox";
import SubTaskIcon from "../../../Core/svgV3/SubtaskIcon";
import getCurrentUser from "./getCurrentUser";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import { updateMyBoard } from "../../../Core/redux/slices/myBoard.slice";
import {
  getAllMyBoardTasks,
  getCollaboratorsForCurrentUser,
  getMyBoardSubtaskById,
  getMyBoardTaskById,
  getMyBoardTasks,
  updateMyBoardParentTaskTimeLog,
  updateMyBoardTask,
} from "../../../Core/redux/api/myBoardAPI";
import TimeInput from "../Task/TimeInput";
import LabelIcon from "../../../Core/svgV3/LabelIcon";
import MyBoardLabel from "../../../Core/CommonV3/MyBoardLabel";
import getStatus from "./getStatus";
import useDebounce from "../../../Core/hooks/useDebounceValue";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";

export const RowLayout = ({ customLeft, children, className, style = {} }) => {
  return (
    <div className={`${className} flex items-center`} style={style}>
      <div className="min-w-[136px] max-w-[156px] out-300-14 text-gray-500">
        {customLeft}
      </div>
      <div className="flex-1">{children}</div>
    </div>
  );
};

export const TaskInput = ({
  disabled = false,
  showError = false,
  placeholder = "",
  inputClassName = "task-input",
  autoSize,
  autoFocus = false,
  type = "textbox",
  value = "",
  onChange,
  onSave = async (e, save = false) => console.log(""),
  name = "",
  plannerScreen = false,
}) => {
  const [focused, setFocused] = useState(false);
  const getWidth = () => {
    const maxWidth = document.getElementById(
      "currentInputBoxMaxWidth"
    )?.clientWidth;
    // Calculate the width dynamically based on the length of the entered text
    const textWidth = value?.length * 6; // You may need to adjust the multiplier based on your font size and styling

    return textWidth < 75
      ? 75
      : textWidth >= maxWidth - 300
      ? maxWidth - 300
      : textWidth; // Ensure a minimum width
  };
  console.log(getWidth(), "max width");
  const DynamicInput = type === "textbox" ? Input.TextArea : Input;
  return (
    <div
      className={`transition-shadow duration-300  ${
        showError && !plannerScreen
          ? "border border-error-600 shadow-input-error"
          : focused && !plannerScreen
          ? "border border-primary-500 shadow-input-focus"
          : disabled && !plannerScreen
          ? "border border-grey-200"
          : plannerScreen
          ? ""
          : "border border-transparent hover:border-gray-200"
      } bg-white  rounded-[4px]  flex items-center w-full`}
    >
      <div
        className={
          plannerScreen
            ? ` ${
                showError
                  ? "border border-error-600 shadow-input-error"
                  : focused
                  ? "border border-primary-500 shadow-input-focus"
                  : disabled
                  ? "border border-grey-200"
                  : plannerScreen
                  ? ""
                  : "border border-transparent hover:border-gray-200"
              }   rounded-[4px] `
            : "w-full"
        }
      >
        <DynamicInput
          name={name}
          value={value}
          bordered={false}
          placeholder={placeholder}
          className={
            plannerScreen ? "out-300-12 text-black flex-1" : inputClassName
          }
          onFocus={() => {
            setFocused(true);
          }}
          autoFocus={autoFocus}
          autoSize={autoSize}
          style={{
            width: plannerScreen ? getWidth() : " 100% ",
            maxWidth: "100%",
          }}
          onBlur={(e) => {
            setFocused(false);

            plannerScreen ? onSave(e, false) : console.log("");
          }}
          onPressEnter={(e) => {
            plannerScreen ? onSave(e, true) : setFocused(true);
          }}
          onChange={(e) => onChange(e, plannerScreen)}
        />
      </div>
    </div>
  );
};
export const ParentTaskSelect = ({ subTask, setSubTask, disabled }) => {
  const { myBoardTasks } = useAppSelector((state) => state.myBoard);
  //   // const { myBoardTasks } = useAppSelector((state) => state.myBoard);

  const [search, setSearch] = useState(null);
  const debouncedValue = useDebounce(search, 500);
  const originalParentGoal = myBoardTasks?.result.find(
    (l) => +l.id === +subTask.parentGoalId
  );
  const [parentGoal, setParentGoal] = useState(originalParentGoal);
  const [showParentGoal, setShowParentGoal] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => setParentGoal(originalParentGoal), [originalParentGoal]);
  useEffect(() => {
    const payload: { limit: number; offset: number; searchQuery?: string } = {
      limit: null,
      offset: null,
    };

    if (debouncedValue) payload.searchQuery = debouncedValue;

    dispatch(getMyBoardTasks(payload));
    dispatch(getCollaboratorsForCurrentUser({}));
  }, [debouncedValue]);

  if (!parentGoal && !showParentGoal) {
    return (
      <button
        className="flex items-center h-[30px] cursor-pointer border border-transparent hover:border-gray-200 w-full rounded pl-2 "
        onClick={() => setShowParentGoal(true)}
        disabled={disabled}
      >
        <PlusIcon className="text-gray-500" />
        <p className="pl-2 out-300-14 leading-5 text-gray-500 capitalize">
          Add a Parent Goal
        </p>
      </button>
    );
  }

  if (parentGoal && !showParentGoal) {
    return (
      <div
        className="flex justify-start items-center px-[14px] py-[5px] gap-x-3 max-w-[196px] cursor-pointer"
        onClick={() => setShowParentGoal(true)}
      >
        <Taskicon className="text-primary-600" />
        <Tooltip title={parentGoal?.name} placement="bottom">
          <div className="flex gap-x-1  items-center justify-start max-w-[140px]">
            <p className="out-300-12 text-gray-900 flex whitespace-nowrap">
              MB-
              {parentGoal?.identifier
                ? parentGoal.identifier
                : originalParentGoal.id}
            </p>
            <p className="out-500-14 text-gray-700 min-w-[50px] truncate">
              {parentGoal?.name}
            </p>
          </div>
        </Tooltip>
      </div>
    );
  }

  return (
    <Popover
      arrow={false}
      placement="bottomLeft"
      open={showParentGoal}
      onOpenChange={() => setShowParentGoal(false)}
      content={
        <div className="flex flex-col p-3 max-h-[308px] ">
          <CustomSearchBox
            width="296px"
            placeholder="Search for a task"
            search={search}
            setSearch={(e) => setSearch(e)}
          />
          <div className="flex flex-col w-full h-[260px] overflow-y-scroll">
            {myBoardTasks?.result.map((parentGoal) => (
              <div
                className="flex  items-center px-3 py-4 gap-x-3 cursor-pointer"
                key={parentGoal.id}
                onClick={async () => {
                  setParentGoal(parentGoal);
                  setSubTask(parentGoal.id);
                  setShowParentGoal(false);
                }}
              >
                <Taskicon className="text-primary-600" />
                <Tooltip title={parentGoal?.name} placement="bottom">
                  <div className="flex gap-x-1  items-center justify-center max-w-[280px]">
                    <p className="out-300-12 text-gray-900">
                      MB -{" "}
                      {parentGoal?.identifier
                        ? parentGoal.identifier
                        : originalParentGoal.id}
                    </p>
                    <p className="out-500-14 text-gray-700 max-w-[200px] truncate">
                      {parentGoal?.name}
                    </p>
                  </div>
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
      }
      trigger={["click"]}
    >
      <div
        className={`flex rounded-[4px]  items-center px-[14px] py-[5px] gap-x-3 max-w-[196px] cursor-pointer ${
          showParentGoal ? "border border-primary-300" : ""
        }`}
        onClick={() => setShowParentGoal(true)}
      >
        <Tooltip title={parentGoal?.name} placement="bottom">
          <div className="flex gap-x-1  items-center justify-start max-w-[140px]">
            <p className="out-300-12 text-gray-900 flex  whitespace-nowrap">
              MB-{" "}
              {parentGoal?.identifier
                ? parentGoal.identifier
                : originalParentGoal.id}
            </p>
            <p className="out-500-14 text-gray-700 min-w-[40px] truncate">
              {parentGoal?.name}
            </p>
          </div>
        </Tooltip>
      </div>
    </Popover>
  );
};

export const VersionSelect = ({ task, setTask, disabled }) => {
  const { releaseVersions } = useAppSelector((state) => state.schedule);

  const versionId = task.releaseVersionId;
  const version = releaseVersions.find((l) => l.id == versionId);
  const [showVersionSelect, setShowVersionSelect] = useState(false);
  const dispatch = useAppDispatch();

  const option = releaseVersions.map((r) => {
    return {
      id: r.id,
      value: r.id,
      label: r.versionName,
      render: () => (
        <div className="flex justify-between items-center">
          <CustomBadge
            text={r.versionName}
            color="#344054"
            className="border border-gray-200"
          />
        </div>
      ),
    };
  });

  if (!version && !showVersionSelect) {
    return (
      <button
        className="flex items-center h-[30px] cursor-pointer border border-transparent hover:border-gray-200 w-full rounded pl-2 "
        onClick={() => setShowVersionSelect(true)}
        disabled={disabled}
      >
        <p className="pl-2 out-300-14 leading-5 text-gray-500 capitalize">
          Select version
        </p>
      </button>
    );
  }

  if (version && !showVersionSelect) {
    return (
      <button
        className="flex items-center h-[30px] cursor-pointer border border-transparent hover:border-gray-200 w-full rounded pl-2 "
        onClick={() => setShowVersionSelect(true)}
        disabled={disabled}
      >
        <CustomBadge
          text={version.versionName}
          color="#344054"
          className="border border-gray-200"
        />
      </button>
    );
  }

  return (
    <CustomSelect
      placeholder="Select version"
      options={option}
      onDropdownVisibleChange={(open) => !open && setShowVersionSelect(false)}
      defaultOpen
      showSearch
      disabled={disabled}
      customDropDownRender={(menu) => {
        return (
          <div>
            <div className="scrollbar-hidden">{menu}</div>
            <button
              onClick={() =>
                dispatch(
                  updateSchedule({ key: "newReleaseVersionModal", value: true })
                )
              }
              className="flex items-center hover:bg-gray-50 w-full py-3 px-3.5 cursor-pointer "
            >
              <PlusIcon className="text-gray-500" />
              <p className="pl-2 out-300-12 leading-5 text-gray-500 capitalize">
                Create new version
              </p>
            </button>
          </div>
        );
      }}
      onChange={(e) => {
        setTask(e);
        setShowVersionSelect(false);
      }}
    />
  );
};

const AddApproversUnMemo = ({
  task,
  selectedUsers,
  setSelectedUsers,
  waddleItData,
  onRemoveUser,
  onAddUser,
}) => {
  const { taskId } = useParams();
  const [showApprover, setShowApprover] = useState(false);
  const dispatch = useAppDispatch();
  const [showWaddledItModal, setShowWaddledItModal] = useState(false);

  const { userList } = useAppSelector((state) => state.overview);
  const { user } = useAppSelector((state) => state.userDetails);

  let header = <></>;

  if (!showApprover) {
    header = (
      <button
        className="flex items-center h-[32px] cursor-pointer border border-transparent hover:border-gray-200 w-full rounded pl-2 "
        onClick={() => setShowApprover(true)}
      >
        <img src="/images/v2/schedule/add-round.svg" />
        <p className="out-500-14 ml-2 text-black">Add Approvers</p>
      </button>
    );
  } else {
    header = (
      <div>
        <MultiSelect
          placeholder="Add Approvers"
          onOpenChange={(open) => {
            setShowApprover(open);
          }}
          autoFocus
          options={userList
            .filter(
              (u) =>
                selectedUsers?.indexOf(u.userId) === -1 &&
                +u.userId !== +user.id
            )
            .map((user) => {
              return {
                id: user.userId,
                value: user.userId,
                label: getUsersName(user),
                render: () => (
                  <div
                    onClick={() => {
                      setSelectedUsers((oldList) => [...oldList, user.userId]);
                      onAddUser(user.userId);
                    }}
                    className="flex items-center "
                  >
                    <CustomAvatar
                      src={user.profilePicture}
                      size={24}
                      title={getUsersName(user)}
                      whiteText
                      color={user.profilePictureColorCode}
                    />

                    <p className="out-300-12 pl-2 text-gray-900 capitalize ">
                      {getUsersName(user)}
                    </p>
                  </div>
                ),
              };
            })}
        />
      </div>
    );
  }

  const shouldShowWaddleIt = task.assigneeId !== user.id && taskId !== "new";

  if (shouldShowWaddleIt) {
    const selfStatus = waddleItData?.find((waddle) => waddle.userId == user.id);
    const handleClick = async (value) => {
      try {
        await client.put("/schedule/waddle-it", {
          userId: user.id,
          goalId: taskId,
          isWaddled: value,
        });
        dispatch(getGoalDetails({ goalId: taskId }));
      } catch (e) {
        console.log(e);
      } finally {
        if (value === 1) {
          setShowWaddledItModal(true);
        }
      }
    };
    if (selfStatus?.isWaddled) {
      header = (
        <div className="flex items-center gap-x-3">
          <Tooltip
            title={
              <div className="">
                <p className="out-500-14 text-white">Approved</p>
                {selfStatus?.waddledOn && (
                  <p className="out-300-12 text-white mt-1">
                    {dayjs(selfStatus?.waddledOn).format(
                      "MMMM DD YYYY, hh:mm a"
                    )}
                  </p>
                )}
              </div>
            }
          >
            <div>
              <CustomButton
                text="Waddled by you"
                className="bg-[#AADAFF] out-500-14 text-white"
                height="30px"
              />
            </div>
          </Tooltip>
          <CustomButton
            text="Undo"
            height="30px"
            onClick={() => handleClick(0)}
            className="bg-transparent text-gray-500"
          />
        </div>
      );
    } else {
      header = (
        <CustomButton
          text="Waddle it !"
          width="100%"
          height="30px"
          disabled={selfStatus ? false : true}
          onClick={() => handleClick(1)}
        />
      );
    }
  }

  const getStatusDetails = (currentUser) => {
    const selfStatus = waddleItData?.find(
      (waddle) => waddle.userId == currentUser.userId
    );
    if (selfStatus) {
      return selfStatus;
    }
    return {};
  };

  return (
    <>
      {header}
      <div>
        {userList.map((user, i) => {
          if (selectedUsers?.indexOf(user.userId) !== -1) {
            const selfStatus = getStatusDetails(user);

            const waddled = selfStatus?.isWaddled == 1;
            return (
              <div
                className="flex py-[2px] mt-2 group hover:border-gray-200 border border-transparent rounded justify-between px-3"
                key={user.userId + i}
              >
                <div className="flex items-center ">
                  <Tooltip
                    title={
                      waddled ? (
                        <div className="">
                          <p className="out-500-14 text-white">Approved</p>
                          {selfStatus?.waddledOn && (
                            <p className="out-300-12 text-white mt-1">
                              {dayjs(selfStatus?.waddledOn).format(
                                "MMMM DD YYYY, hh:mm a"
                              )}
                            </p>
                          )}
                        </div>
                      ) : (
                        "Approval pending"
                      )
                    }
                  >
                    <img
                      src={
                        waddled
                          ? "/images/v2/schedule/waddled.svg"
                          : "/images/v2/schedule/remove-round.svg"
                      }
                      className="mr-2"
                    />
                  </Tooltip>
                  <CustomAvatar
                    size={24}
                    src={user.profilePicture}
                    title={getUsersName(user)}
                    whiteText
                    color={user.profilePictureColorCode}
                  />

                  <p className="out-300-14 pl-2 text-gray-900 capitalize ">
                    {getUsersName(user)}
                  </p>
                </div>
                <button
                  onClick={() => {
                    setSelectedUsers((oldList) => [
                      ...oldList.filter((old) => +old !== +user.userId),
                    ]);

                    onRemoveUser(user.userId);
                  }}
                  className={`hidden ${
                    shouldShowWaddleIt ? "hidden" : "group-hover:block"
                  } `}
                >
                  <CrossIcon className="text-gray-500" />
                </button>
              </div>
            );
          }
        })}
      </div>
      <WaddledItModal
        visible={showWaddledItModal}
        onClose={() => setShowWaddledItModal(false)}
      />
    </>
  );
};
const AddApprovers = React.memo(AddApproversUnMemo);
export { AddApprovers };

export const DueDate = ({ setTask, task }) => {
  const [date, setDate] = useState(task.dueDate);
  const [showSelect, setShowSelect] = useState(false);

  if (!showSelect && !date) {
    return (
      <button
        className="flex items-center border border-transparent hover:border-gray-200 w-full rounded pl-2 h-[30px] cursor-pointer "
        onClick={() => setShowSelect(true)}
      >
        <CalendarIcon className="text-gray-500" />
        <span className="out-300-14 text-gray-500 ml-2">Select Date</span>
      </button>
    );
  }

  if (date && !showSelect) {
    return (
      <button
        className="flex items-center border border-transparent hover:border-gray-200 w-full rounded pl-2 h-[30px] cursor-pointer "
        onClick={() => setShowSelect(true)}
      >
        <CalendarIcon className="text-gray-500" />
        <span className="out-500-14 text-black ml-2">
          {dayjs(date).format("MMMM DD YYYY")}
        </span>
      </button>
    );
  }

  return (
    <div>
      <DatePicker
        className="w-full  bg-transparent rounded-[4px] border-gray-200"
        format="DD MMM YYYY"
        placeholder="Select Date"
        autoFocus
        open
        suffixIcon={<CalendarIcon className="text-gray-500" />}
        onChange={(date) => {
          setDate(date);
          setTask(date);
          setShowSelect(false);
        }}
      />
    </div>
  );
};
export const TaskDueDate = ({ setTask, task, type }) => {
  const [date, setDate] = useState(task.dueDate);
  const [showSelect, setShowSelect] = useState(false);
  useEffect(
    () => setDate(type === "Start" ? task.startDate : task.endDate),
    [task, type]
  );
  if (!showSelect && !date) {
    return (
      <button
        className="flex items-center border border-transparent hover:border-gray-200 w-full rounded  h-[30px] cursor-pointer "
        onClick={() => setShowSelect(true)}
      >
        <span className="out-300-14 text-gray-500 ml-2">Add {type} Date</span>
      </button>
    );
  }

  if (date && !showSelect) {
    return (
      <button
        className="flex items-center border border-transparent hover:border-gray-200 w-full rounded  h-[30px] cursor-pointer "
        onClick={() => setShowSelect(true)}
      >
        <span className="out-500-14 text-black ml-2">
          {dayjs(date).format("DD/MM/YYYY")}
        </span>
      </button>
    );
  }

  return (
    <div>
      <DatePicker
        className="w-full  bg-transparent rounded-[4px] border-gray-200"
        format="DD MMM YYYY"
        placeholder="Select Date"
        autoFocus
        open
        suffixIcon={<CalendarIcon className="text-gray-500" />}
        onChange={(date) => {
          setDate(date);
          setTask(date);
          setShowSelect(false);
        }}
      />
    </div>
  );
};

export const CollaboratorSelect = ({
  placeholder,
  task,
  setTask,
  onEdit,
  type,
  disabled,
}) => {
  const { userList } = useAppSelector((state) => state.overview);
  const [showSelect, setShowSelect] = useState(false);
  const [currentSelectedUser, setCurrentSelectedUser] = useState(null);

  useEffect(() => {
    let userId = "";
    if (type === "assignee") {
      userId = task.assigneeId;
    } else {
      userId = task.reporterId;
    }
    const user = userList.find((user) => user.userId == userId);
    if (user) {
      setCurrentSelectedUser(user);
    } else {
      setCurrentSelectedUser(null);
    }
  }, [task]);

  const handleMenuItemClick = (user) => {
    setCurrentSelectedUser(user);
    setShowSelect(false);
    if (type === "assignee") {
      setTask({
        ...task,
        assigneeId: user.userId,
      });
    } else {
      setTask({
        ...task,
        reporterId: user.userId,
      });
    }
    onEdit(user.userId);
  };

  if (!showSelect) {
    return (
      <button
        className="flex border border-transparent hover:border-gray-200 w-full rounded pl-2 h-[30px] cursor-pointer items-center "
        onClick={() => setShowSelect(true)}
        disabled={disabled}
      >
        {currentSelectedUser ? (
          <>
            <CustomAvatar
              src={currentSelectedUser?.profilePicture}
              size={24}
              title={getUsersName(currentSelectedUser)}
              whiteText
              color={currentSelectedUser?.profilePictureColorCode}
            />

            <p className="out-500-14 pl-2 text-gray-900 capitalize ">
              {getUsersName(currentSelectedUser)}
            </p>
          </>
        ) : (
          <>{placeholder}</>
        )}
      </button>
    );
  }

  return (
    <CustomSelect
      placeholder={placeholder}
      onDropdownVisibleChange={(open) => !open && setShowSelect(false)}
      defaultOpen
      showSearch
      disabled={disabled}
      options={userList.map((user) => {
        return {
          id: user.userId,
          value: user.userId,
          label: getUsersName(user),

          render: () => (
            <div
              className="flex items-center "
              onClick={() => {
                handleMenuItemClick(user);
              }}
            >
              <CustomAvatar
                src={user.profilePicture}
                size={24}
                title={getUsersName(user)}
                whiteText
                color={user.profilePictureColorCode}
              />

              <p className="out-300-12 pl-2 text-gray-900 capitalize ">
                {getUsersName(user)}
              </p>
            </div>
          ),
        };
      })}
    />
  );
};

const SelectStatus = ({ task, setStatusId, statusId }) => {
  const [swimlaneIdState, setSwimlaneIdState] = useState(task.swimlaneId);
  const [open, setOpen] = useState(false);
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const statusArray = [
    {
      id: "1",
      statusId: "1",
      title: "To Do",
      positionIndex: "1",
    },
    {
      id: "2",
      statusId: "2",
      title: "In Progress",
      positionIndex: "1",
    },
    {
      id: "3",
      statusId: "3",
      title: "Done",
      positionIndex: "1",
    },
  ];
  const handleStatusChange = async (newStatusId, swimlaneId) => {
    setSwimlaneIdState(swimlaneId);
    setOpen(false);
    setStatusId({ statusId: newStatusId, swimlaneId });
    //todo implement status change for frontend
    // const allUsers = task.collaborato
    // await client.post("/notifications/create", {
    //   notificationType: "33",
    //   goalId: task.id,

    //   allUsers: [{ userId: task.assigneeId },{userId:}],
    //   projectId: task.projectId,
    //   meta: {
    //     oldStatusId: task.statusId,
    //     newStatusId: newStatusId,
    //     goalId: task.id,
    //   },
    // });

    // dispatch(getTaskActivityLog({ goalId: taskId }));
  };

  const StatusItem = ({ status, text, swimlaneId }) => {
    const { color, className } = getStatusDetails(status);

    return (
      <div
        onClick={() => handleStatusChange(status, swimlaneId)}
        role="button"
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        <CustomBadge text={text} color={color} className={className} dot />
        {swimlaneId == swimlaneIdState && (
          <CheckIcon className="text-primary-600" />
        )}
      </div>
    );
  };

  const currentSwimlane = statusArray.find((s) => s.id == task.statusId);
  const { color, className } = getStatusDetails(currentSwimlane?.statusId);

  return (
    <>
      {task.id ? (
        <Popover
          arrow={false}
          placement="bottomLeft"
          open={open}
          onOpenChange={handleOpenChange}
          content={
            <div className="w-[200px] bg-white shadow-lg rounded-lg ">
              {statusArray.map((s) => (
                <StatusItem
                  key={s.id}
                  status={s.statusId}
                  text={s.title}
                  swimlaneId={s.id}
                />
              ))}
            </div>
          }
          trigger={["click"]}
        >
          <button
            className="flex items-center gap-x-2.5 cursor-pointer"
            onClick={() => setOpen(true)}
          >
            <CustomBadge
              text={currentSwimlane?.title || "To Do"}
              color={color}
              className={className}
              dot
            />
            <ChevronIcon />
          </button>
        </Popover>
      ) : (
        <CustomBadge
          text={currentSwimlane?.title || "To Do"}
          color={color}
          className={className}
          dot
        />
      )}
    </>
  );
};

export const SubtaskButton = ({
  subTaskLength,
  handleClick,
  disabled = false,
}) => {
  return (
    <Tooltip
      placement="bottom"
      title={subTaskLength === 0 ? "Add Subtasks" : "Subtasks"}
    >
      <div
        className={`relative ${disabled ? "cursor-not-allowed" : ""}`}
        onClick={!disabled ? handleClick : undefined}
      >
        {subTaskLength === 0 ? (
          <div className="absolute top-[-3px] right-[-4px] bg-primary-100 rounded-full w-[14px] h-[14px]">
            <PlusIcon className="text-primary-600" />
          </div>
        ) : (
          <div className="absolute top-[-3px] right-[-4px] bg-success-600 rounded-full w-[14px] h-[14px]">
            <p className="text-center text-white out-600-8 pt-[1px]">
              {subTaskLength}
            </p>
          </div>
        )}
        <SubTaskIcon />
      </div>
    </Tooltip>
  );
};

const statusOptions = [
  {
    id: 1,
    value: 1,
    label: "To Do",
    render: () => getStatus({ statusId: 1, showIcon: false }),
  },
  {
    id: 2,
    value: 2,
    label: "In Progress",
    render: () => getStatus({ statusId: 2, showIcon: false }),
  },
  {
    id: 3,
    value: 3,
    label: "Done",
    render: () => getStatus({ statusId: 3, showIcon: false }),
  },
];

export const SelectStatusMyBoard = ({
  task,
  setTask,
  statusId,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(statusId);

  useEffect(() => {
    setCurrentStatus(statusId);
  }, [statusId]);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleStatusChange = async (newStatusId) => {
    setCurrentStatus(newStatusId);
    setOpen(false);
    await setTask(newStatusId);
  };

  const StatusItem = ({ status, label }) => {
    const isActive = status === currentStatus;

    return (
      <div
        onClick={() => handleStatusChange(status)}
        role="button"
        className="flex cursor-pointer hover:bg-gray-50 justify-between px-[14px] py-2.5 capitalize"
      >
        {statusOptions.find((option) => option.id === status)?.render()}
        {isActive && <CheckIcon className="text-primary-600" />}
        {/* <span className="ml-2">{label}</span> */}
      </div>
    );
  };

  const currentStatusOption = statusOptions.find(
    (option) => option.id === currentStatus
  );
  const { render } = currentStatusOption || {
    render: () => <span>Unknown</span>,
  };

  return (
    <>
      <Popover
        arrow={false}
        placement="bottomLeft"
        open={!disabled && open}
        onOpenChange={handleOpenChange}
        content={
          <div className="w-[200px] bg-white shadow-lg rounded-lg">
            {statusOptions.map((option) => (
              <StatusItem
                key={option.id}
                status={option.id}
                label={option.label}
              />
            ))}
          </div>
        }
        trigger={["click"]}
      >
        <button
          className="flex items-center gap-x-2.5 cursor-pointer"
          onClick={() => setOpen(true)}
        >
          {render()}
        </button>
      </Popover>
    </>
  );
};

export const StatusSelect = ({ task, setTask }) => {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(task.statusId);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  useEffect(() => {
    setStatus(task.statusId);
  }, [task]);

  return (
    <Popover
      getPopupContainer={(parent) =>
        parent?.parentElement.parentElement.parentElement
      }
      content={
        <div className="min-w-[200px]">
          <CustomSelect
            options={statusOptions}
            defaultOpen
            showSearch
            onChange={(e) => {
              setOpen(false);
              setTask(e);
              setStatus(e);
            }}
          />
        </div>
      }
      arrow={false}
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottom"
      trigger="click"
      destroyTooltipOnHide
    >
      <button className="whitespace-nowrap">
        {status && getStatus({ statusId: status, showIcon: true })}
      </button>
    </Popover>
  );
};

export const TimeSelect = ({
  visible = false,
  task,
  setTask,
  setEditedTaskDetails = (newTime) => console.log(""),
  type = "timeLogged",
}) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const { taskId, subtaskId } = useParams();

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Popover
      getPopupContainer={(parent) => parent?.parentElement}
      content={
        <div className="min-w-[200px]">
          <TimeInput
            className=""
            autoFocus
            value={task.totalTimeLogged || ""}
            addNewTimeToPreviousTime
            onChange={(e) => {
              type === "timeLogged"
                ? setTask({ ...task, totalTimeLogged: e })
                : setTask({ ...task, estimatedTime: e });
            }}
            onOk={async (newTime: string, totalTime: string) => {
              setOpen(false);
              if (taskId || subtaskId) {
                if (task.id) {
                  {
                    setEditedTaskDetails(newTime);
                  }
                }
              } else {
                if (task.id) {
                  {
                    type === "timeLogged"
                      ? await dispatch(
                          updateMyBoardTask({
                            totalTimeLogged: newTime,
                            goalId: task.id,
                          })
                        )
                      : await dispatch(
                          updateMyBoardTask({
                            estimatedTime: newTime,
                            goalId: task.id,
                          })
                        );
                    if (task.parentGoalId) {
                      dispatch(getMyBoardSubtaskById({ taskId: task.id }));
                    } else {
                      dispatch(getMyBoardTaskById({ taskId: task.id }));
                    }
                  }
                }
              }
              if (task?.parentGoalId) {
                // Dispatch the async thunk to update the parent task's time log
                const payload = {
                  parentGoalId: task.parentGoalId,
                  minutesToAdd: totalTime,
                };
                dispatch(updateMyBoardParentTaskTimeLog(payload));
              }
            }}
          />
        </div>
      }
      arrow={false}
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomLeft"
      trigger={"click"}
      destroyTooltipOnHide
    >
      {taskId || subtaskId ? (
        <p
          className={`
        ${
          type === "timeLogged"
            ? task.totalTimeLogged
              ? "out-500-14"
              : "out-300-14"
            : task.estimatedTime
            ? "out-500-14"
            : "out-300-14"
        }
         text-gray-700 pl-[2px]`}
        >
          {type === "timeLogged"
            ? task.totalTimeLogged || " 0w 0d 00h 00m"
            : task.estimatedTime || "0w 0d 00h 00m"}
        </p>
      ) : (
        <button>
          <Tooltip title="Log Time">
            <img
              className="cursor-pointer"
              src="/images/v2/schedule/hourglass-02.svg"
            />
          </Tooltip>
        </button>
      )}
    </Popover>
  );
};
export const LabelSelect = ({
  task,
  setTask,
  visible = false,
  labelOptions,
  disabled = false,
}) => {
  const dispatch = useAppDispatch();
  const { taskId, subtaskId } = useParams();

  const { user } = useAppSelector((state) => state.userDetails);
  const { tenantDetails } = useAppSelector((state) => state.tenant);
  const { labels } = useAppSelector((state) => state.schedule);

  const [open, setOpen] = useState(false);
  const [newLabel, setNewLabel] = useState({
    labelId: 0,
    name: "",
    labelColorId: 0,
  });

  useEffect(() => {
    const currentLabel = labels?.find((l) => l.id == task?.labelId);

    if (currentLabel) {
      setNewLabel({
        labelId: currentLabel.id,
        name: currentLabel.name,
        labelColorId: currentLabel.labelColorId,
      });
    }
  }, [labels, task]);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  // console.log(task, "This is from label select");

  return !task?.createdById || +task?.createdById === +user.id ? (
    <Popover
      getPopupContainer={(parent) =>
        parent?.parentElement?.parentElement?.parentElement
      }
      content={
        <div className="min-w-[200px]">
          <CustomSelect
            placeholder="Enter Label Name"
            options={labelOptions}
            defaultOpen
            showSearch
            customDropDownRender={(menu) => {
              return (
                <div className="min-w-[200px]">
                  <div className="scrollbar-hidden">{menu}</div>
                  <button
                    onClick={() => {
                      dispatch(
                        updateSchedule({ key: "newLabelModal", value: true })
                      );
                      setOpen(false);
                    }}
                    className="flex items-center py-3 px-3.5 hover:underline w-full cursor-pointer "
                  >
                    <PlusIconSecondary className="text-gray-600" />
                    <p className="pl-2 leading-5 text-gray-800 capitalize out-400-12">
                      Create new label
                    </p>
                  </button>
                </div>
              );
            }}
            onChange={(e) => {
              setTask(e);
              setNewLabel(labels.find((l) => l.id == e));
              setOpen(false);
            }}
          />
        </div>
      }
      arrow={false}
      open={!disabled && open}
      onOpenChange={handleOpenChange}
      placement="bottomLeft"
      trigger={"click"}
      destroyTooltipOnHide
    >
      {task?.labelId ? (
        <button className="truncate">
          <MyBoardLabel
            labelColorId={newLabel.labelColorId}
            text={newLabel.name}
          />
        </button>
      ) : taskId || subtaskId ? (
        <p className="flex gap-x-1 text-gray-500 out-300-14">
          <PlusIcon className="text-gray-500" />
          Add Label
        </p>
      ) : (
        <Tooltip title="Add a label">
          {visible ? (
            <button>
              <LabelIcon />
            </button>
          ) : (
            <div />
          )}
        </Tooltip>
      )}
    </Popover>
  ) : (
    <div
      onClick={() =>
        dispatch(
          updateDashboard({
            key: "alertPopupModal",
            value: {
              visible: true,
              data: {
                title: `Action not possible`,
                subtitle: `Cannot edit labels of tasks which are not created by you `,
                description: ``,
              },
            },
          })
        )
      }
    >
      {task?.labelId &&
      (+task?.createdById === +user.id || !task?.createdById) ? (
        <button className="truncate ">
          <MyBoardLabel
            labelColorId={newLabel.labelColorId}
            text={newLabel.name}
          />
        </button>
      ) : taskId || subtaskId ? (
        <p className="flex gap-x-1 text-gray-500 out-300-14">
          <PlusIcon className="text-gray-500" />
          Add Label
        </p>
      ) : (
        <Tooltip title="Add a label">
          {visible ? (
            <button>
              <LabelIcon />
            </button>
          ) : (
            <div />
          )}
        </Tooltip>
      )}
    </div>
  );
};

export const TaskScreenLabelSelect = ({
  task,
  setTask,
  visible = false,
  labelOptions,
}) => {
  const dispatch = useAppDispatch();
  const { taskId, subtaskId } = useParams();

  const { user } = useAppSelector((state) => state.userDetails);
  const [showLabelSelect, setShowLabelSelect] = useState(false);

  const { tenantDetails } = useAppSelector((state) => state.tenant);
  const { labels } = useAppSelector((state) => state.schedule);

  const [open, setOpen] = useState(false);
  const [newLabel, setNewLabel] = useState({
    labelId: 0,
    name: "",
    labelColorId: 0,
  });

  useEffect(() => {
    const currentLabel = labels?.find((l) => l.id == task?.labelId);

    if (currentLabel) {
      setNewLabel({
        labelId: currentLabel.id,
        name: currentLabel.name,
        labelColorId: currentLabel.labelColorId,
      });
    }
  }, [labels, task]);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  console.log(task, "This is from label select");

  if (task?.labelId && !showLabelSelect) {
    return (
      <button
        className="flex items-center h-[30px] cursor-pointer border border-transparent hover:border-gray-200 w-full rounded pl-2 "
        onClick={() => setShowLabelSelect(true)}
        disabled={!task?.createdById || +task?.createdById !== +user.id}
      >
        {newLabel.labelId ? (
          <MyBoardLabel
            labelColorId={newLabel.labelColorId}
            text={newLabel.name}
          />
        ) : (
          <p className="flex gap-x-1 text-gray-500 out-300-14">
            <PlusIcon className="text-gray-500" />
            Add Label
          </p>
        )}
      </button>
    );
  }

  return !task?.createdById || +task?.createdById === +user.id ? (
    <CustomSelect
      placeholder="Enter Label Name"
      options={labelOptions}
      defaultOpen={showLabelSelect}
      showSearch
      onDropdownVisibleChange={(open) => !open && setShowLabelSelect(false)}
      customDropDownRender={(menu) => {
        return (
          <div className="min-w-[200px]">
            <div className="scrollbar-hidden">{menu}</div>
            <button
              onClick={() => {
                dispatch(updateSchedule({ key: "newLabelModal", value: true }));
                setOpen(false);
              }}
              className="flex items-center py-3 px-3.5 hover:underline w-full cursor-pointer "
            >
              <PlusIconSecondary className="text-gray-600" />
              <p className="pl-2 leading-5 text-gray-800 capitalize out-400-12">
                Create new label
              </p>
            </button>
          </div>
        );
      }}
      onChange={(e) => {
        setTask(e);
        setNewLabel(labels.find((l) => l.id == e));
        setOpen(false);
      }}
    />
  ) : (
    <div
      onClick={() =>
        dispatch(
          updateDashboard({
            key: "alertPopupModal",
            value: {
              visible: true,
              data: {
                title: `Action not possible`,
                subtitle: `Cannot edit labels of tasks which are not created by you `,
                description: ``,
              },
            },
          })
        )
      }
    >
      {task?.labelId &&
      (+task?.createdById === +user.id || !task?.createdById) ? (
        <button className="truncate ">
          <MyBoardLabel
            labelColorId={newLabel.labelColorId}
            text={newLabel.name}
          />
        </button>
      ) : taskId || subtaskId ? (
        <p className="flex gap-x-1 text-gray-500 out-300-14">
          <PlusIcon className="text-gray-500" />
          Add Label
        </p>
      ) : (
        <Tooltip title="Add a label">
          {visible ? (
            <button>
              <LabelIcon />
            </button>
          ) : (
            <div />
          )}
        </Tooltip>
      )}
    </div>
  );
};
export const PrioritySelect = ({ visible = false, task, setTask }) => {
  // const priority = task.priorityId;
  const [priorityId, setPriorityId] = useState(task.priorityId || 1);
  const { taskId, subtaskId } = useParams();

  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const getLabel = (priority, bold = false) => {
    const { color, priorityName } = getPriorityDetails(priority);
    return (
      <div className="flex items-center ">
        <PriorityFlag
          style={{
            color,
          }}
        />
        <p
          className="ml-2 out-300-14"
          style={{ color, fontWeight: bold ? 500 : 300 }}
        >
          {priorityName}
        </p>
      </div>
    );
  };

  const option = [
    {
      id: 1,
      value: 1,
      label: "Low",
      render: () => getLabel(1),
    },
    {
      id: 2,
      value: 2,
      label: "Medium",
      render: () => getLabel(2),
    },
    {
      id: 3,
      value: 3,
      label: "High",
      render: () => getLabel(3),
    },
    {
      id: 4,
      value: 4,
      label: "Urgent",
      render: () => getLabel(4),
    },
  ];

  useEffect(() => {
    setPriorityId(task.priorityId);
  }, [task]);

  return (
    <Popover
      getPopupContainer={(parent) => parent?.parentElement}
      content={
        <div className="min-w-[200px]">
          <CustomSelect
            placeholder="Select Priority"
            options={option}
            defaultOpen
            showSearch
            onChange={(e) => {
              setOpen(false);
              setTask(e);
              setPriorityId(e);
            }}
          />
        </div>
      }
      arrow={false}
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomLeft"
      trigger={"click"}
      destroyTooltipOnHide
    >
      <div>
        {taskId || subtaskId
          ? getLabel(+task.priorityId)
          : (priorityId || visible) && (
              <PriorityFlag priorityId={+task.priorityId} />
            )}
      </div>
    </Popover>
  );
};
export const TaskPrioritySelect = ({ visible = false, task, setTask }) => {
  // const priority = task.priorityId;
  const [priorityDropdown, setPriorityDropdown] = useState(false);

  const [priorityId, setPriorityId] = useState(task.priorityId || 1);
  const { taskId, subtaskId } = useParams();

  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const getLabel = (priority, bold = false) => {
    const { color, priorityName } = getPriorityDetails(priority);
    return (
      <div className="flex items-center ">
        <PriorityFlag
          style={{
            color,
          }}
        />
        <p
          className="ml-2 out-300-14"
          style={{ color, fontWeight: bold ? 500 : 300 }}
        >
          {priorityName}
        </p>
      </div>
    );
  };

  const option = [
    {
      id: 1,
      value: 1,
      label: "Low",
      render: () => getLabel(1),
    },
    {
      id: 2,
      value: 2,
      label: "Medium",
      render: () => getLabel(2),
    },
    {
      id: 3,
      value: 3,
      label: "High",
      render: () => getLabel(3),
    },
    {
      id: 4,
      value: 4,
      label: "Urgent",
      render: () => getLabel(4),
    },
  ];

  useEffect(() => {
    setPriorityId(task.priorityId);
  }, [task]);
  if (!priorityDropdown) {
    return (
      <button
        className="flex items-center h-[30px] cursor-pointer border border-transparent hover:border-gray-200 w-full rounded pl-2 "
        onClick={() => setPriorityDropdown(true)}
        // disabled={disabled}
      >
        {taskId || subtaskId
          ? getLabel(+task.priorityId)
          : (priorityId || visible) && (
              <PriorityFlag priorityId={+task.priorityId} />
            )}
      </button>
    );
  }
  return (
    <CustomSelect
      placeholder="Select Priority"
      options={option}
      onDropdownVisibleChange={(open) => !open && setPriorityDropdown(false)}
      showSearch
      defaultOpen
      onChange={(e) => {
        setOpen(false);
        setTask(e);
        setPriorityId(e);
      }}
    />
  );
};

export const UserSelect = ({ task }) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userDetails);

  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { collaboratorsForTask } = useAppSelector((state) => state.myBoard);

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const handleMultiSelect = (current) => {
    dispatch(
      updateMyBoard({
        key: "collaboratorsForTask",
        value: collaboratorsForTask.includes(current.userId)
          ? collaboratorsForTask.filter((item) => item !== current.userId)
          : [...collaboratorsForTask, current.userId],
      })
    );
  };

  const onClose = () => {
    setOpen(false);
    dispatch(
      updateMyBoard({
        key: "collaboratorsForTask",
        value: [],
      })
    );
  };
  const onRequest = async () => {
    setOpen(false);
    if (task.id) {
      await dispatch(
        updateMyBoardTask({
          goalId: task.id,
          collaborators: collaboratorsForTask,
        })
      );
      dispatch(
        updateMyBoard({
          key: "collaboratorsForTask",
          value: [],
        })
      );
      dispatch(
        updateDashboard({
          key: "alertPopupModal",
          value: {
            visible: true,
            data: {
              title: `Sent Collaboration Request`,
              subtitle: `Collaboration request was sent for task`,
              description: `${task.name}`,
            },
          },
        })
      );
    }
  };

  return (
    <Popover
      getPopupContainer={(parent) =>
        parent?.parentElement?.parentElement?.parentElement?.parentElement
      }
      content={
        <div className="min-w-[200px] bg-white rounded-lg">
          <CustomInputBox
            className="px-3 pt-2"
            placeholder="Search for a name"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            autoFocus
          />

          <div className="overflow-y-scroll h-[200px]">
            <p className="px-3 pt-1.5 text-gray-500 out-300-14">
              Select 1 or multiple members
            </p>

            {tenantMembers
              .filter(
                (u) =>
                  task?.collaborators?.includes(u.userId) ||
                  task.assigneeId?.includes(user.id)
              )
              .filter(
                (u) =>
                  u.userId === user?.id &&
                  (u.firstname.toLowerCase().includes(search.toLowerCase()) ||
                    u.lastname.toLowerCase().includes(search.toLowerCase()))
              )
              .map((current, index) => (
                <div
                  role="button"
                  key={`${current.userId}${index}`}
                  className="flex items-center gap-x-3 mt-1.5 px-3"
                  onClick={() => handleMultiSelect(current)}
                >
                  <Checkbox
                    checked={
                      task?.collaborators?.includes(current.userId) ||
                      task.assigneeId?.includes(current.userId)
                    }
                  />
                  <CustomAvatar
                    src={current.profilePicture}
                    size={24}
                    title={getUsersName(current)}
                    whiteText
                    color={current.profilePictureColorCode}
                  />

                  <p className="text-gray-900 capitalize out-300-12">
                    {getUsersName(current)}
                  </p>
                </div>
              ))}
            {tenantMembers
              .filter((u) => task?.collaborators?.includes(u.userId))
              .filter(
                (u) =>
                  u.userId !== user?.id &&
                  (u.firstname.toLowerCase().includes(search.toLowerCase()) ||
                    u.lastname.toLowerCase().includes(search.toLowerCase()))
              )
              .map((current, index) => (
                <div
                  role="button"
                  key={`${current.userId}${index}`}
                  className="flex items-center gap-x-3 mt-1.5 px-3"
                  onClick={() => handleMultiSelect(current)}
                >
                  <Checkbox
                    checked={task?.collaborators?.includes(current.userId)}
                  />
                  <CustomAvatar
                    src={current.profilePicture}
                    size={24}
                    title={getUsersName(current)}
                    whiteText
                    color={current.profilePictureColorCode}
                  />

                  <p className="text-gray-900 capitalize out-300-12">
                    {getUsersName(current)}
                  </p>
                </div>
              ))}
            {tenantMembers
              .filter((u) => !task?.collaborators?.includes(u.userId))
              .filter(
                (u) =>
                  u.userId !== user?.id &&
                  (u.firstname.toLowerCase().includes(search.toLowerCase()) ||
                    u.lastname.toLowerCase().includes(search.toLowerCase()))
              )
              .map((current, index) => (
                <div
                  role="button"
                  key={`${current.userId}${index}`}
                  className="flex items-center gap-x-3 mt-1.5 px-3"
                  onClick={() => handleMultiSelect(current)}
                >
                  <Checkbox
                    checked={collaboratorsForTask?.includes(current.userId)}
                  />
                  <CustomAvatar
                    src={current.profilePicture}
                    size={24}
                    title={getUsersName(current)}
                    whiteText
                    color={current.profilePictureColorCode}
                  />

                  <p className="text-gray-900 capitalize out-300-12">
                    {getUsersName(current)}
                  </p>
                </div>
              ))}
          </div>

          <div className="z-10 flex items-center justify-end w-full p-3 bg-white rounded-b-lg">
            <CustomButton
              text="Cancel"
              className="text-gray-500 bg-transparent"
              height="30px"
              width="63px"
              onClick={onClose}
            />
            <CustomButton
              text="Request"
              height="30px"
              width="71px"
              className="bg-transparent text-primary-600"
              onClick={onRequest}
            />
          </div>
        </div>
      }
      arrow={false}
      open={open}
      onOpenChange={(open) => setOpen(open)}
      placement="bottomLeft"
      trigger={"click"}
    >
      {task && getCurrentUser({ task })}
    </Popover>
  );
};

export { SelectStatus };
