import React, { useCallback, useEffect, useState } from "react";
import PageTitle from "../PageTitle";
import CustomSearchBox from "../../../Core/CommonV2/CustomSearchBox";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { PhaseIcon } from "../../../Core/svgV2/PhaseIcon";
import CustomOnboardingDatePicker from "../../../Core/CommonV2/CustomOnboardingDatePicker";
import { useParams, useSearchParams } from "react-router-dom";
import ChangePhaseDateWarningModal from "../Modals/ChangePhaseDateWarningModal";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  getProjectPhases,
  hasUnfinishedGoals,
  updatePhase,
} from "../../../Core/redux/api/scheduleAPI";
import PhaseModal from "../../Schedule/Modals/PhaseModal";
import ProjectsSettingsWrapper from "../ProjectsSettingsWrapper";
import usePermission from "../../../Core/hooks/usePermission";
import dayjs from "dayjs";
import { DeleteIcon } from "../../../Core/svgV2/DeleteIcon";
import DeletePhaseModal from "../Modals/DeletePhaseModal";
import { useGetProjectDetails } from "../../../Core/redux/ReactQueryHooksV3/useProjectAPI";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import CompletePhaseModal from "../../Dashboard/Modals/CompletePhaseModal";

const PhaseCard = ({ phaseId, phaseName, disabled, color, phase }) => {
  const [hover, setHover] = useState(false);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const [focused, setFocused] = useState(false);

  const [newPhaseStartDate, setNewPhaseStartDate] = useState(null);
  const [newPhaseEndDate, setNewPhaseEndDate] = useState(null);

  const [warningModal, setWarningModal] = useState(false);
  const [showDeletePhaseModal, setShowDeleteModal] = useState(false);

  const {
    data: projectDetails,
    isLoading: isLoadingProjectDetails,
    error: projectDetailsError,
  } = useGetProjectDetails(searchParams.get("projectId"));

  const { hasPermission: PHASE_AND_SPRINT_EDIT } = usePermission("6");

  const handleConfirm = async () => {
    const updateObj: any = {
      projectId: searchParams.get("projectId"),
      phaseId: phase.phaseId,
      projectPhaseId: phase.projectPhaseId,
    };
    if (newPhaseStartDate) {
      updateObj.startDate = newPhaseStartDate;
    }
    if (newPhaseEndDate) {
      updateObj.endDate = newPhaseEndDate;
    }
    await dispatch(updatePhase(updateObj));

    setWarningModal(false);
    dispatch(getProjectPhases({ projectId: searchParams.get("projectId") }));
  };

  const handleDelete = async () => {
    const updateObj: any = {
      projectId: searchParams.get("projectId"),
      phaseId: phase.phaseId,
      isActive: 0,
      projectPhaseId: phase.projectPhaseId,
    };

    await dispatch(updatePhase(updateObj));

    setWarningModal(false);
    dispatch(getProjectPhases({ projectId: searchParams.get("projectId") }));
  };

  const { hasPermission: canEditPhase } = usePermission("6");

  const diff =
    phase.startDate && phase.endDate
      ? dayjs(phase.endDate).diff(phase.startDate, "days")
      : 0;

  const disableDate = (current) => {
    const projectStartDate = dayjs(projectDetails?.projectDetails?.startDate);
    const projectEndDate = dayjs(projectDetails?.projectDetails?.endDate);
    return (
      current &&
      (current.isBefore(projectStartDate, "day") ||
        current.isAfter(projectEndDate, "day"))
    );
  };

  const renderPhaseButton = () => {
    if (!canEditPhase) return null;

    const handleEndPhaseClick = async () => {
      if (!projectDetails.isEnded) {
        const showErrorMessageModal = await dispatch(
          hasUnfinishedGoals({
            phaseId: +phase.phaseId,
            projectId: +projectDetails?.projectDetails.id,
          })
        );
        console.log(
          showErrorMessageModal.payload.result,
          "showErrorMessagemOdal"
        );
        if (showErrorMessageModal?.payload?.result) {
          dispatch(
            updateDashboard({
              key: "errorMessageModal",
              value: { visible: true, type: "phase" },
            })
          );

          dispatch(
            updateDashboard({
              key: "completePhaseModal",
              value: {
                phase,
                action: "end",
                visible: false,
              },
            })
          );
        } else {
          dispatch(
            updateDashboard({
              key: "completePhaseModal",
              value: {
                visible: true,
                edit: false,
                action: "end",
                phase: phase,
              },
            })
          );
        }
      }
    };

    const handleStartPhaseClick = () => {
      if (!projectDetails?.isEnded) {
        dispatch(
          updateDashboard({
            key: "completePhaseModal",
            value: {
              phase,
              action: "start",
              visible: true,
              edit: false,
            },
          })
        );
      }
    };

    return (
      <>
        {phase?.isCompleted === 1 ? (
          <div></div>
        ) : phase?.isOngoing === 1 && PHASE_AND_SPRINT_EDIT ? (
          <div
            role="button"
            className={`rounded-lg ${
              projectDetails?.progress === "100"
                ? " bg-[#AADAFF] text-white"
                : " bg-primary-50 text-primary-700"
            } h-[22px] gap-x-[7px] flex items-center justify-center cursor-pointer out-500-12 py-1 pl-[6px] pr-2 ${
              projectDetails?.isEnded ? "opacity-30" : ""
            }`}
            onClick={handleEndPhaseClick}
          >
            <div
              className={`w-[6px] h-[6px] rounded-xl`}
              style={{ backgroundColor: color }}
            ></div>
            End Phase
          </div>
        ) : PHASE_AND_SPRINT_EDIT ? (
          <div
            className={`rounded-lg ${
              projectDetails?.progress === "100"
                ? "bg-[#AADAFF]"
                : "bg-primary-600"
            } h-[22px] gap-x-[7px] flex items-center justify-center out-500-12 text-white py-1 px-2 cursor-pointer ${
              projectDetails?.isEnded ? "opacity-30" : ""
            }`}
            role="button"
            onClick={handleStartPhaseClick}
          >
            Start Phase
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div
      className=" rounded-lg h-[222px] min-w-[180px] p-4 relative flex flex-col gap-y-6 hover:shadow-[0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)] transform duration-200"
      style={{
        border: focused
          ? `1px solid ${color}`
          : hover
          ? "1px solid #F9FBFE"
          : `1px solid ${color}`,
        backgroundColor: disabled ? "#F7FAFD" : "#F9FBFE",
        cursor: disabled ? "not-allowed" : "pointer",
        boxShadow: hover
          ? "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)"
          : "",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onFocus={() => setFocused(true)}
      // onFocus={()=>conso}
      //onBlur={() => setFocused(false)}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center ">
          <PhaseIcon height="20" width="20" phaseId={+phaseId} />

          <p className="out-500-12 ml-3.5">
            {phaseName} {phase.phaseIteration}
          </p>
        </div>
        {canEditPhase && (
          <button onClick={() => setShowDeleteModal(true)}>
            <DeleteIcon width="20" height="20" className="text-gray-500" />
          </button>
        )}
      </div>
      <CustomOnboardingDatePicker
        value={phase.startDate}
        onChange={(event) => {
          setNewPhaseStartDate(event);
          setWarningModal(true);
        }}
        disabled={!canEditPhase}
        placeholder="Start Date"
        disableDate={disableDate}
      />
      <CustomOnboardingDatePicker
        value={phase.endDate}
        onChange={(event) => {
          setNewPhaseEndDate(event);
          setWarningModal(true);
        }}
        disabled={!canEditPhase}
        placeholder="End Date"
        disableDate={disableDate}
      />
      <div>
        <div className="out-500-10 text-gray-400 uppercase">Duration</div>
        <div className="flex justify-between items-center mt-1.5">
          <div className="out-500-12 text-gray-700">{diff} days</div>
          {renderPhaseButton()}
        </div>
      </div>

      <ChangePhaseDateWarningModal
        visible={warningModal}
        onClose={() => {
          setNewPhaseStartDate(null);
          setNewPhaseEndDate(null);
          setWarningModal(false);
        }}
        onConfirm={() => handleConfirm()}
      />
      {showDeletePhaseModal && (
        <DeletePhaseModal
          visible={showDeletePhaseModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={() => handleDelete()}
        />
      )}
    </div>
  );
};

const Phases = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { phases } = useAppSelector((state) => state.schedule);
  const { completePhaseModal } = useAppSelector((state) => state.dashboard);
  const [addPhaseModal, setAddPhaseModal] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(getProjectPhases({ projectId: searchParams.get("projectId") }));
  }, [searchParams]);

  const handleSearch = useCallback(
    (phases) => {
      return phases.filter((phase) =>
        `${phase.phases} ${phase.phaseIteration}`
          .toLowerCase()
          .includes(search.toLowerCase())
      );
    },
    [search, phases]
  );

  const { hasPermission: canAddPhase } = usePermission("5");

  return (
    <div className="pb-40">
      <PageTitle title={"Phases"} subtext={"Manage project phases."} />
      <div className="my-6 flex justify-between">
        <CustomSearchBox
          placeholder="Search by name"
          style={{ width: "50%" }}
          search={search}
          setSearch={setSearch}
        />
        {canAddPhase && (
          <CustomButton
            height="30px"
            text="Add Phase"
            onClick={() => setAddPhaseModal(true)}
          />
        )}
      </div>
      <ProjectsSettingsWrapper>
        <div>
          <p className="out-300-14 text-gray-500">Edit existing phases</p>

          <div className="flex flex-wrap gap-8 mt-6 w-[667px]">
            {handleSearch(phases).map((phase) => (
              <PhaseCard
                key={phase.projectPhaseId}
                phaseId={phase.phaseId}
                phaseName={phase.phases}
                color={phase.color}
                disabled={false}
                phase={phase}
              />
            ))}
          </div>
        </div>
        {/* <div className="mt-[61px]">
        <p className="out-300-14 text-gray-500">Add more phases</p>

        <div className="flex flex-wrap gap-8 mt-6 w-[667px]">
          {phases.map((phase) => (
            <PhaseCard
              key={phase.projectPhaseId}
              phaseId={1}
              phaseName={"Ideation"}
              color={color[+phase.phaseId - 1]}
              disabled={true}
              phase={phase}
            />
          ))}
        </div>
      </div> */}
      </ProjectsSettingsWrapper>
      <PhaseModal
        visible={addPhaseModal}
        onClose={() => setAddPhaseModal(false)}
      />
      {completePhaseModal.visible && <CompletePhaseModal />}
    </div>
  );
};

export default Phases;
